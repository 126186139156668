import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useEffectOnce } from "../functions/useEffectOnce";

import UpcomingTournamentsContainer from "../components/feeds/UpcomingTournamentsContainer";

function PageUpcoming(props) {
    const { trackPageView } = useMatomo();

    // Scroll to top of page
    useEffectOnce(() => {
        window.scrollTo(0, 0);
    });

    useEffectOnce(() => {
        document.title = "Upcoming Tournaments - Tournaments - Twitch Apex Stats";
        trackPageView();
    });

    return (
        <>
            <UpcomingTournamentsContainer />
        </>
    );
}

export default PageUpcoming;
