import { useEffect } from "react";
import axios from "axios";
import { Row, Col, Badge, Card, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";

import { useEffectOnce } from "../../functions/useEffectOnce";

function TournamentDetails(props) {
    const navigate = useNavigate();
    const { trackPageView, trackEvent } = useMatomo();
    const [isLoaded, setIsLoaded] = useState(false);
    const [tournamentData, setTournamentData] = useState(null);
    const [showAllMatches, setShowAllMatches] = useState(true);
    const [matchesToShow, setMatchesToShow] = useState([]);
    const [matchesContent, setMatchesContent] = useState(null);
    const [dateString, setDateString] = useState(new Date());
    const numOfMatchesToShow = 20;

    // Get the ALGS upcoming tournament
    useEffectOnce(() => {
        let requestUrl = "";
        if (props.category === "ALGS") {
            requestUrl = "https://algs.tas.gg/api/feeds/" + props.timeframe + "/" + props.eventID;
        }
        if (props.category === "Battlefy") {
            requestUrl = "https://battlefy.tas.gg/feeds/" + props.timeframe + "/" + props.eventID;
        }
        if (props.category === "Oversight") {
            requestUrl = "https://oe.tas.gg/feeds/" + props.timeframe + "/" + props.eventID;
        }
        axios.get(requestUrl).then((response) => {
            setTournamentData(response.data);
            setDateString(new Date(response.data.timestamp * 1000));
            setIsLoaded(true);
        });
    });

    // Set page title
    useEffect(() => {
        if (isLoaded === true) {
            document.title = tournamentData.name + " - Tournaments - Twitch Apex Stats";
            trackPageView();
        }
    }, [isLoaded, tournamentData, trackPageView]);

    // Check the latest tournament info every minute
    useEffectOnce(() => {
        const timer = setInterval(() => {
            let requestUrl = "";
            if (props.category === "ALGS") {
                requestUrl = "https://algs.tas.gg/api/feeds/" + props.timeframe + "/" + props.eventID;
            }
            if (props.category === "Battlefy") {
                requestUrl = "https://battlefy.tas.gg/feeds/" + props.timeframe + "/" + props.eventID;
            }
            if (props.category === "Oversight") {
                requestUrl = "https://oe.tas.gg/feeds/" + props.timeframe + "/" + props.eventID;
            }
            axios.get(requestUrl).then((response) => {
                setTournamentData(response.data);
                setDateString(new Date(response.data.timestamp * 1000));
                setIsLoaded(true);
            });
        }, 60000);
        // clearing interval
        return () => clearInterval(timer);
    });

    useEffect(() => {
        if (isLoaded === true) {
            // Show all matches
            if (showAllMatches === true) {
                setMatchesToShow(tournamentData.matches);
            }
            // Show only the first three matches
            else {
                setMatchesToShow(tournamentData.matches.slice(0, numOfMatchesToShow));
            }
        }
    }, [isLoaded, tournamentData, showAllMatches]);

    // Redirect to ongoing page if the tournament is ongoing
    useEffect(() => {
        if (isLoaded === true) {
            if (props.timeframe === "upcoming") {
                // Calculate the time difference between now and the tournament start time
                const timeDiff = tournamentData.timestamp - Math.floor(Date.now() / 1000);

                const redirectTimer = setTimeout(() => {
                    navigate("/ongoing/" + tournamentData.category + "/" + props.eventID);
                }, timeDiff * 1000);
                return () => clearTimeout(redirectTimer);
            }
        }
    }, [isLoaded, tournamentData, navigate, props.timeframe, props.eventID]);

    // Generate matchesContent
    useEffect(() => {
        setMatchesContent(
            matchesToShow.map((match) => (
                <Col
                    xs="6"
                    md="4"
                    xl="3"
                    className="text-center"
                    key={match.match_id}
                >
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip style={{ top: "-9999px", left: "-9999px" }}>
                                Generate a chat command for:
                                <br />
                                <b>
                                    {tournamentData.name}: {match.name}
                                </b>
                            </Tooltip>
                        }
                    >
                        <Link
                            to={"/match/" + tournamentData.category + "/" + match.match_id}
                            className="btn btn-dark btn-sm m-1 p-1"
                        >
                            {match.name}
                        </Link>
                    </OverlayTrigger>
                </Col>
            ))
        );
    }, [matchesToShow, tournamentData]);

    return (
        <>
            {isLoaded ? (
                <>
                    <Col xs="12">
                        <Card className="bg-secondary m-2 shadow-sm">
                            <Card.Header className="p-1">
                                <h2 className="text-center p-0 m-0">
                                    {tournamentData.category === "ALGS" ? (
                                        <Badge
                                            bg="danger"
                                            className="shadow-sm"
                                        >
                                            ALGS
                                        </Badge>
                                    ) : null}
                                    {tournamentData.category === "Battlefy" ? (
                                        <Badge
                                            bg="primary"
                                            className="shadow-sm"
                                        >
                                            Battlefy
                                        </Badge>
                                    ) : null}
                                    {tournamentData.category === "Oversight" ? (
                                        <Badge
                                            bg="warning"
                                            className="shadow-sm text-dark"
                                        >
                                            Oversight Esports
                                        </Badge>
                                    ) : null}{" "}
                                    {tournamentData.name}
                                </h2>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip style={{ top: "-9999px", left: "-9999px" }}>
                                            Date/time is shown in your local timezone region of:{" "}
                                            <b>{Intl.DateTimeFormat().resolvedOptions().timeZone}</b>
                                        </Tooltip>
                                    }
                                >
                                    <div className="text-center fs-6 fw-bold">
                                        {dateString.toLocaleString(navigator.languages[0], {
                                            weekday: "long",
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                            hour: "numeric",
                                            minute: "numeric",
                                            second: "numeric",
                                        })}
                                    </div>
                                </OverlayTrigger>
                            </Card.Header>
                            <Card.Body className="p-0">
                                {tournamentData.event_page !== undefined ? (
                                    <center className="mb-4 fs-5">
                                        <a
                                            href={tournamentData.event_page}
                                            className="text-white fw-bold"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            View Tournament Information Page on {props.category}
                                        </a>
                                    </center>
                                ) : null}
                                <div className="text-center fw-bold fs-3">Matches:</div>
                                {tournamentData.matches.length === 0 ? (
                                    <div className="text-center fst-italic">Tournament has no matches setup yet.</div>
                                ) : null}
                                <Row>
                                    {matchesContent}
                                    {tournamentData.matches.length > numOfMatchesToShow ? (
                                        <>
                                            {showAllMatches ? (
                                                <Col className="text-center">
                                                    <button
                                                        className="btn btn-light btn-sm m-1 p-1 text-dark fw-bold"
                                                        onClick={() => {
                                                            setShowAllMatches(false);
                                                            trackEvent({
                                                                category: "Tournament",
                                                                action: "Show-Less-Matches",
                                                                name: tournamentData.name + " - Show less matches",
                                                            });
                                                        }}
                                                    >
                                                        Show less matches
                                                    </button>
                                                </Col>
                                            ) : (
                                                <Col className="text-center">
                                                    <button
                                                        className="btn btn-light btn-sm m-1 p-1 text-dark fw-bold"
                                                        onClick={() => {
                                                            setShowAllMatches(true);
                                                            trackEvent({
                                                                category: "Tournament",
                                                                action: "Show-All-Matches",
                                                                name: tournamentData.name + " - Show all matches",
                                                            });
                                                        }}
                                                    >
                                                        Show all matches
                                                    </button>
                                                </Col>
                                            )}
                                        </>
                                    ) : null}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </>
            ) : (
                <div className="text-center col-auto mx-auto">
                    <Spinner
                        animation="border"
                        variant="primary"
                        style={{
                            width: "3rem",
                            height: "3rem",
                        }}
                    />
                </div>
            )}
        </>
    );
}

export default TournamentDetails;
