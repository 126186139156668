import { ListGroup, ListGroupItem, InputGroup, Form } from "react-bootstrap";
import { ClipboardPlus } from "react-bootstrap-icons";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import Clipboard from "react-clipboard.js";

function BotCommands(props) {
    const { trackEvent } = useMatomo();

    const isTeamResults = props.teamResults ? true : false;
    const cmdExampleName = isTeamResults ? "team" : "now";

    return (
        <ListGroup>
            <ListGroupItem active="true">Commands</ListGroupItem>
            <ListGroupItem>
                <h3>Nightbot</h3>
                <b>Command Contents:</b>
                <br />
                <InputGroup>
                    <Clipboard
                        data-clipboard-target="#nightbotCMD"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                            trackEvent({
                                category: "CMD-Builder",
                                action: "Copied-Nightbot-Command",
                                name: props.matchName + " - Nightbot Command Copied",
                            });
                        }}
                    >
                        <ClipboardPlus /> Copy Text
                    </Clipboard>
                    <Form.Control
                        id="nightbotCMD"
                        readOnly
                        value={`$(urlfetch ${props.commandURL})`}
                    />
                </InputGroup>
                <br />
                <b>Example of adding to Nightbot:</b>
                <br />
                <code>
                    !addcom !{cmdExampleName} $(urlfetch {props.commandURL})
                </code>
            </ListGroupItem>
            <ListGroupItem>
                <h3>StreamElements Bot</h3>
                <b>Command Contents:</b>
                <br />
                <InputGroup>
                    <Clipboard
                        data-clipboard-target="#sebotCMD"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                            trackEvent({
                                category: "CMD-Builder",
                                action: "Copied-StreamElements-Command",
                                name: props.matchName + " - StreamElements Command Copied",
                            });
                        }}
                    >
                        <ClipboardPlus /> Copy Text
                    </Clipboard>
                    <Form.Control
                        id="sebotCMD"
                        readOnly
                        value={`\${customapi.${props.commandURL}}`}
                    />
                </InputGroup>
                <br />
                <b>Example of adding to StreamElements Bot:</b>
                <br />
                <code>{`!command add ${cmdExampleName} \${customapi.${props.commandURL}}`}</code>
            </ListGroupItem>
            <ListGroupItem>
                <h3>StreamLabs CloudBot</h3>
                <b>Command Contents:</b>
                <br />
                <InputGroup>
                    <Clipboard
                        data-clipboard-target="#slcbCMD"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                            trackEvent({
                                category: "CMD-Builder",
                                action: "Copied-StreamLabs-Cloudbot-Command",
                                name: props.matchName + " - StreamLabs Cloudbot Command Copied",
                            });
                        }}
                    >
                        <ClipboardPlus /> Copy Text
                    </Clipboard>
                    <Form.Control
                        id="slcbCMD"
                        readOnly
                        value={`{readapi.${props.commandURL}}`}
                    />
                </InputGroup>
                <br />
                <b>Example of adding to StreamLabs CloudBot:</b>
                <br />
                <code>{`!addcommand !${cmdExampleName} {readapi.${props.commandURL}}`}</code>
            </ListGroupItem>
            <ListGroupItem>
                <h3>StreamLabs Desktop ChatBot</h3>
                <b>Command Contents:</b>
                <br />
                <InputGroup>
                    <Clipboard
                        data-clipboard-target="#sldcbCMD"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                            trackEvent({
                                category: "CMD-Builder",
                                action: "Copied-StreamLabs-Desktop-Command",
                                name: props.matchName + " - StreamLabs Desktop Command Copied",
                            });
                        }}
                    >
                        <ClipboardPlus /> Copy Text
                    </Clipboard>
                    <Form.Control
                        id="sldcbCMD"
                        readOnly
                        value={`$readapi(${props.commandURL})`}
                    />
                </InputGroup>
            </ListGroupItem>
            <ListGroupItem>
                <h3>PhantomBot</h3>
                <b>Command Contents:</b>
                <br />
                <InputGroup>
                    <Clipboard
                        data-clipboard-target="#phantombotCMD"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                            trackEvent({
                                category: "CMD-Builder",
                                action: "Copied-PhantomBot-Command",
                                name: props.matchName + " - PhantomBot Command Copied",
                            });
                        }}
                    >
                        <ClipboardPlus /> Copy Text
                    </Clipboard>
                    <Form.Control
                        id="phantombotCMD"
                        readOnly
                        value={`(customapi ${props.commandURL})`}
                    />
                </InputGroup>
            </ListGroupItem>
            <ListGroupItem>
                <h3>Fossabot</h3>
                <b>Command Contents:</b>
                <br />
                <InputGroup>
                    <Clipboard
                        data-clipboard-target="#fossabotCMD"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                            trackEvent({
                                category: "CMD-Builder",
                                action: "Copied-Fossabot-Command",
                                name: props.matchName + " - Fossabot Command Copied",
                            });
                        }}
                    >
                        <ClipboardPlus /> Copy Text
                    </Clipboard>
                    <Form.Control
                        id="fossabotCMD"
                        readOnly
                        value={`$(customapi ${props.commandURL})`}
                    />
                </InputGroup>
            </ListGroupItem>
            <ListGroupItem>
                <h3>BotRix</h3>
                <b>Command Contents:</b>
                <br />
                <InputGroup>
                    <Clipboard
                        data-clipboard-target="#botrixCMD"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                            trackEvent({
                                category: "CMD-Builder",
                                action: "Copied-BotRix-Command",
                                name: props.matchName + " - BotRix Command Copied",
                            });
                        }}
                    >
                        <ClipboardPlus /> Copy Text
                    </Clipboard>
                    <Form.Control
                        id="botrixCMD"
                        readOnly
                        value={`fetch[${props.commandURL}]`}
                    />
                </InputGroup>
                <br />
                <b>Example of adding to BotRix:</b>
                <br />
                <code>
                    !addcom !{cmdExampleName} fetch[{props.commandURL}]
                </code>
            </ListGroupItem>
            <ListGroupItem>
                <h3>WizeBot</h3>
                <b>Command Contents:</b>
                <br />
                <InputGroup>
                    <Clipboard
                        data-clipboard-target="#wizebotCMD"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                            trackEvent({
                                category: "CMD-Builder",
                                action: "Copied-WizeBot-Command",
                                name: props.matchName + " - WizeBot Command Copied",
                            });
                        }}
                    >
                        <ClipboardPlus /> Copy Text
                    </Clipboard>
                    <Form.Control
                        id="wizebotCMD"
                        readOnly
                        value={`$urlcall(${props.commandURL})`}
                    />
                </InputGroup>
                <br />
                <b>Example of adding to WizeBot:</b>
                <br />
                <code>
                    !cmd add !{cmdExampleName} $urlcall({props.commandURL})
                </code>
            </ListGroupItem>
            <ListGroupItem>
                <h3>Moobot</h3>
                <b>In the management UI command creator:</b>
                <br />
                <ul>
                    <li>"Response" should be set to "URL fetch - Full (plain) response"</li>
                    <li>"URL response type" should be set to "Plain text"</li>
                    <li>"Request method" should be set to "GET"</li>
                    <li>"URL to fetch" should be set to the URL below</li>
                </ul>
                <InputGroup>
                    <Clipboard
                        data-clipboard-target="#moobotCMD"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                            trackEvent({
                                category: "CMD-Builder",
                                action: "Copied-Moobot-Command",
                                name: props.matchName + " - Moobot Command Copied",
                            });
                        }}
                    >
                        <ClipboardPlus /> Copy Text
                    </Clipboard>
                    <Form.Control
                        id="moobotCMD"
                        readOnly
                        value={`${props.commandURL}`}
                    />
                </InputGroup>
            </ListGroupItem>
        </ListGroup>
    );
}

export default BotCommands;
