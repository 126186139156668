import React, { useState } from "react";
import { ListGroup, ListGroupItem, Accordion, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffectOnce } from "../../functions/useEffectOnce";
import { Link } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";

function ALGSAllMatchesMatchListing(props) {
    const { trackEvent } = useMatomo();
    const [hasMultipleRounds, setHasMultipleRounds] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffectOnce(() => {
        if (props.league === "challenger-circuit") {
            setHasMultipleRounds(true);
        }
        if (props.league === "qualifiers") {
            setHasMultipleRounds(true);
        }
        setIsLoaded(true);
    });

    return isLoaded
        ? Object.keys(props.matches).map((matchEvent) => (
              <ListGroup
                  key={props.league + "-" + props.split + "-" + props.region + "-" + matchEvent}
                  className="mt-2"
              >
                  <ListGroupItem
                      key={props.league + "-" + props.split + "-" + props.region + "-" + matchEvent + "-all"}
                      active
                  >
                      <h4>{matchEvent}</h4>
                  </ListGroupItem>
                  {hasMultipleRounds ? (
                      <ListGroupItem
                          key={props.league + "-" + props.split + "-" + props.region + "-" + matchEvent + "-rounds"}
                          className="p-0"
                      >
                          <Accordion flush>
                              {Object.keys(props.matches[matchEvent]).map((round) => (
                                  <Accordion.Item
                                      eventKey={round}
                                      key={
                                          props.league +
                                          "-" +
                                          props.split +
                                          "-" +
                                          props.region +
                                          "-" +
                                          matchEvent +
                                          "-" +
                                          round
                                      }
                                  >
                                      <Accordion.Header
                                          onClick={() => {
                                              trackEvent({
                                                  category: "All ALGS Matches",
                                                  action: "Select Round",
                                                  name:
                                                      "Selected " +
                                                      props.league +
                                                      ": " +
                                                      props.split +
                                                      ": " +
                                                      props.region +
                                                      ": " +
                                                      matchEvent +
                                                      ": " +
                                                      round,
                                              });
                                          }}
                                      >
                                          {Number(round) === Object.keys(props.matches[matchEvent]).length
                                              ? "Finals"
                                              : Number(round) === Object.keys(props.matches[matchEvent]).length - 1
                                              ? "Semi-finals"
                                              : "Round " + round}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                          <Row>
                                              {props.matches[matchEvent][round].map((match) => (
                                                  <Col
                                                      xs="12"
                                                      sm="6"
                                                      lg="4"
                                                      xxl="3"
                                                      key={
                                                          props.league +
                                                          "-" +
                                                          props.split +
                                                          "-" +
                                                          props.region +
                                                          "-" +
                                                          matchEvent +
                                                          "-" +
                                                          round +
                                                          "-" +
                                                          match.match_id
                                                      }
                                                  >
                                                      <OverlayTrigger
                                                          placement="top"
                                                          delay={{ show: 250, hide: 0 }}
                                                          overlay={
                                                              <Tooltip
                                                                  style={{
                                                                      fontSize: "0.6rem",
                                                                      top: "-9999px",
                                                                      left: "-9999px",
                                                                  }}
                                                              >
                                                                  Generate a chat command for:
                                                                  <br />
                                                                  <b>{match.match_name}</b>
                                                              </Tooltip>
                                                          }
                                                      >
                                                          <Link to={"/match/ALGS/" + match.match_id}>
                                                              <button className="btn btn-primary btn-sm m-2">
                                                                  {match.match_name}
                                                              </button>
                                                          </Link>
                                                      </OverlayTrigger>
                                                  </Col>
                                              ))}
                                          </Row>
                                      </Accordion.Body>
                                  </Accordion.Item>
                              ))}
                          </Accordion>
                      </ListGroupItem>
                  ) : (
                      props.matches[matchEvent].map((match) => (
                          <ListGroupItem
                              key={
                                  props.league +
                                  "-" +
                                  props.split +
                                  "-" +
                                  props.region +
                                  "-" +
                                  matchEvent +
                                  "-" +
                                  match.match_id
                              }
                          >
                              <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 0 }}
                                  overlay={
                                      <Tooltip style={{ fontSize: "0.6rem", top: "-9999px", left: "-9999px" }}>
                                          Generate a chat command for:
                                          <br />
                                          <b>{match.match_name}</b>
                                      </Tooltip>
                                  }
                              >
                                  <Link to={"/match/ALGS/" + match.match_id}>
                                      <button className="btn btn-primary btn-sm me-2 mb-2">{match.match_name}</button>
                                  </Link>
                              </OverlayTrigger>
                              <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 0 }}
                                  overlay={
                                      <Tooltip style={{ fontSize: "0.6rem", top: "-9999px", left: "-9999px" }}>
                                          Generate a chat command for an individual team's stats for:
                                          <br />
                                          <b>{match.match_name}</b>
                                      </Tooltip>
                                  }
                              >
                                  <Link to={"/match/ALGS-Team/" + match.match_id}>
                                      <button className="btn btn-primary btn-sm">
                                          <span className="fw-bold">Individual Team Stats for:</span>
                                          <br />
                                          {match.match_name}
                                      </button>
                                  </Link>
                              </OverlayTrigger>
                          </ListGroupItem>
                      ))
                  )}
              </ListGroup>
          ))
        : null;
}

export default ALGSAllMatchesMatchListing;
