// Upcoming Events Container
//
// This component is used to display the upcoming events in the main page.
//
// Get the data from the API and display it in a table.
//

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Spinner, Card } from "react-bootstrap";
import { useMatomo } from "@datapunt/matomo-tracker-react";

import { useEffectOnce } from "../../functions/useEffectOnce";

import UpcomingTournamentContainer from "./UpcomingTournamentContainer";

function UpcomingTournamentsContainer() {
    const { trackEvent } = useMatomo();
    const [upcomingTournamentsALGS, setUpcomingTournamentsALGS] = useState([]);
    const [upcomingTournamentsBattlefy, setUpcomingTournamentsBattlefy] = useState([]);
    const [upcomingTournaments, setUpcomingTournaments] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isALGSLoaded, setIsALGSLoaded] = useState(false);
    const [isBattlefyLoaded, setIsBattlefyLoaded] = useState(false);
    const [numToShow, setNumToShow] = useState(6);

    const viewingIncrement = 6;

    const [upcomingTournamentsContent, setUpcomingTournamentsContent] = useState(<>placeholder</>);

    // Get the ALGS upcoming tournaments
    useEffectOnce(() => {
        axios
            .get("https://algs.tas.gg/api/feeds/upcoming")
            .then((response) => {
                setUpcomingTournamentsALGS(response.data);
            })
            .catch((error) => {
                console.log(error);
                setUpcomingTournamentsALGS([]);
            })
            .finally(() => {
                setIsALGSLoaded(true);
            });
    });

    // Check the latest ALGS upcoming tournaments every minute
    useEffectOnce(() => {
        const timer = setInterval(() => {
            axios
                .get("https://algs.tas.gg/api/feeds/upcoming")
                .then((response) => {
                    setUpcomingTournamentsALGS(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    setUpcomingTournamentsALGS([]);
                })
                .finally(() => {
                    setIsALGSLoaded(true);
                });
        }, 60000);
        // clearing interval
        return () => clearInterval(timer);
    });

    // Get the Battlefy upcoming tournaments
    useEffectOnce(() => {
        axios
            .get("https://battlefy.tas.gg/feeds/upcoming")
            .then((response) => {
                setUpcomingTournamentsBattlefy(response.data);
            })
            .catch((error) => {
                console.log(error);
                setUpcomingTournamentsBattlefy([]);
            })
            .finally(() => {
                setIsBattlefyLoaded(true);
            });
    });

    // Check the latest Battlefy upcoming tournaments every minute
    useEffectOnce(() => {
        const timer = setInterval(() => {
            axios
                .get("https://battlefy.tas.gg/feeds/upcoming")
                .then((response) => {
                    setUpcomingTournamentsBattlefy(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    setUpcomingTournamentsBattlefy([]);
                })
                .finally(() => {
                    setIsBattlefyLoaded(true);
                });
        }, 60000);
        // clearing interval
        return () => clearInterval(timer);
    });

    // Check if the ALGS and Battlefy upcoming tournaments are loaded
    useEffect(() => {
        if (isALGSLoaded === true || isBattlefyLoaded === true) {
            setIsLoaded(true);
        }
    }, [isALGSLoaded, isBattlefyLoaded]);

    // Combine upcoming tournaments feeds
    useEffect(() => {
        if (isLoaded === true) {
            let combinedTournaments = [];

            // Combine the ALGS upcoming tournaments
            combinedTournaments = combinedTournaments.concat(upcomingTournamentsALGS);

            // Combine the Battlefy upcoming tournaments
            combinedTournaments = combinedTournaments.concat(upcomingTournamentsBattlefy);

            // order by timestamp
            combinedTournaments.sort((a, b) => {
                return a.timestamp - b.timestamp;
            });

            setUpcomingTournaments(combinedTournaments);
        }
    }, [isLoaded, upcomingTournamentsALGS, upcomingTournamentsBattlefy]);

    // Display the upcoming tournaments content
    useEffect(() => {
        if (isLoaded === true) {
            let tournamentsArray = upcomingTournaments.map((tournament) => (
                <UpcomingTournamentContainer
                    key={
                        tournament.category +
                        "-" +
                        tournament.info_url_slug_a +
                        "-" +
                        tournament.info_url_slug_b +
                        "-" +
                        tournament.timestamp
                    }
                    name={tournament.name}
                    category={tournament.category}
                    timestamp={tournament.timestamp}
                    matches={tournament.matches}
                    info_url_slug_a={tournament.info_url_slug_a}
                    info_url_slug_b={tournament.info_url_slug_b}
                    event_page={tournament.event_page === undefined ? false : tournament.event_page}
                />
            ));

            setUpcomingTournamentsContent(tournamentsArray.slice(0, numToShow));
        }
    }, [upcomingTournaments, numToShow, isLoaded]);

    return (
        <Row>
            <Col className="col-12">
                <Card className="bg-dark text-white">
                    <Card.Header>
                        <h3 className="text-center">Upcoming Tournaments</h3>
                        {isLoaded && upcomingTournaments.length > 0 ? (
                            <>
                                Showing{" "}
                                {numToShow > upcomingTournaments.length ? upcomingTournaments.length : numToShow} of{" "}
                                {upcomingTournaments.length} upcoming tournaments
                            </>
                        ) : null}
                    </Card.Header>
                    <Card.Body>
                        {isLoaded ? (
                            upcomingTournaments.length > 0 ? (
                                <Row>{upcomingTournamentsContent}</Row>
                            ) : (
                                <>No upcoming tournaments currently.</>
                            )
                        ) : (
                            <div className="text-center col-auto mx-auto">
                                <Spinner
                                    animation="border"
                                    variant="primary"
                                    style={{
                                        width: "3rem",
                                        height: "3rem",
                                    }}
                                />
                            </div>
                        )}
                        {numToShow > viewingIncrement || numToShow < upcomingTournaments.length ? (
                            <div className="text-center col-auto mx-auto">
                                {numToShow > viewingIncrement ? (
                                    <button
                                        className="btn btn-primary btn-sm me-2"
                                        onClick={() => {
                                            setNumToShow(numToShow - viewingIncrement);
                                            trackEvent({
                                                category: "Upcoming-Tournaments",
                                                action: "Show-Less",
                                                name: "Show Less Upcoming Tournaments",
                                            });
                                        }}
                                    >
                                        {" "}
                                        Show Less
                                    </button>
                                ) : null}
                                {numToShow < upcomingTournaments.length ? (
                                    <button
                                        className="btn btn-primary btn-sm ms-2"
                                        onClick={() => {
                                            setNumToShow(numToShow + viewingIncrement);
                                            trackEvent({
                                                category: "Upcoming-Tournaments",
                                                action: "Show-More",
                                                name: "Show More Upcoming Tournaments",
                                            });
                                        }}
                                    >
                                        {" "}
                                        Show More
                                    </button>
                                ) : null}
                            </div>
                        ) : null}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default UpcomingTournamentsContainer;
