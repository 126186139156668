import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import ALGSCommandBuilder from "../components/algs/ALGSCommandBuilder";
import ALGSTeamCommandBuilder from "../components/algs/ALGSTeamCommandBuilder";
import ALSCGSCommandBuilder from "../components/als/ALSCGSCommandBuilder";
import ALSDGSCommandBuilder from "../components/als/ALSDGSCommandBuilder";
import BattlefyCommandBuilder from "../components/battlefy/BattlefyCommandBuilder";
import OversightCommandBuilder from "../components/oversight/OversightCommandBuilder";
import { useEffectOnce } from "../functions/useEffectOnce";

function PageMatch(props) {
    const { category, matchID } = useParams();
    const navigate = useNavigate();

    // Scroll to top of page
    useEffectOnce(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            {window.history.length > 1 ? (
                <div className="text-center">
                    <button
                        className="btn btn-primary"
                        onClick={() => navigate(-1)}
                    >
                        Go Back
                    </button>
                </div>
            ) : null}
            {category === "ALGS" ? <ALGSCommandBuilder matchID={matchID} /> : null}
            {category === "ALGS-Team" ? <ALGSTeamCommandBuilder matchID={matchID} /> : null}
            {category === "ALSCGS" ? <ALSCGSCommandBuilder matchID={matchID} /> : null}
            {category === "ALSDGS" ? <ALSDGSCommandBuilder matchID={matchID} /> : null}
            {category === "Battlefy" ? <BattlefyCommandBuilder matchID={matchID} /> : null}
            {category === "Oversight" ? <OversightCommandBuilder matchID={matchID} /> : null}
            {window.history.length > 1 ? (
                <div className="text-center">
                    <button
                        className="btn btn-primary"
                        onClick={() => navigate(-1)}
                    >
                        Go Back
                    </button>
                </div>
            ) : null}
        </>
    );
}

export default PageMatch;
