// Ongoing Events Container
//
// This component is used to display the ongoing events in the main page.
//
// Get the data from the API and display it in a table.
//

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Spinner, Card } from "react-bootstrap";
import { useMatomo } from "@datapunt/matomo-tracker-react";

import { useEffectOnce } from "../../functions/useEffectOnce";

import OngoingTournamentContainer from "./OngoingTournamentContainer";

function OngoingTournamentsContainer() {
    const { trackEvent } = useMatomo();
    const [ongoingTournamentsALGS, setOngoingTournamentsALGS] = useState([]);
    const [ongoingTournamentsBattlefy, setOngoingTournamentsBattlefy] = useState([]);
    const [ongoingTournamentsOversight, setOngoingTournamentsOversight] = useState([]);
    const [ongoingTournaments, setOngoingTournaments] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isALGSLoaded, setIsALGSLoaded] = useState(false);
    const [isBattlefyLoaded, setIsBattlefyLoaded] = useState(false);
    const [isOversightLoaded, setIsOversightLoaded] = useState(false);
    const [numToShow, setNumToShow] = useState(6);

    const viewingIncrement = 6;

    const [ongoingTournamentsContent, setOngoingTournamentsContent] = useState(<>placeholder</>);

    // Get the ALGS ongoing tournaments
    useEffectOnce(() => {
        axios
            .get("https://algs.tas.gg/api/feeds/ongoing")
            .then((response) => {
                setOngoingTournamentsALGS(response.data);
            })
            .catch((error) => {
                console.log(error);
                setOngoingTournamentsALGS([]);
            })
            .finally(() => {
                setIsALGSLoaded(true);
            });
    });

    // Check the latest ongoing ALGS tournaments every minute
    useEffectOnce(() => {
        const timer = setInterval(() => {
            axios
                .get("https://algs.tas.gg/api/feeds/ongoing")
                .then((response) => {
                    setOngoingTournamentsALGS(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    setOngoingTournamentsALGS([]);
                })
                .finally(() => {
                    setIsALGSLoaded(true);
                });
        }, 60000);
        // clearing interval
        return () => clearInterval(timer);
    });

    // Get the Battlefy ongoing tournaments
    useEffectOnce(() => {
        axios
            .get("https://battlefy.tas.gg/feeds/ongoing")
            .then((response) => {
                setOngoingTournamentsBattlefy(response.data);
            })
            .catch((error) => {
                console.log(error);
                setOngoingTournamentsBattlefy([]);
            })
            .finally(() => {
                setIsBattlefyLoaded(true);
            });
    });

    // Check the latest ongoing Battlefy tournaments every minute
    useEffectOnce(() => {
        const timer = setInterval(() => {
            axios
                .get("https://battlefy.tas.gg/feeds/ongoing")
                .then((response) => {
                    setOngoingTournamentsBattlefy(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    setOngoingTournamentsBattlefy([]);
                })
                .finally(() => {
                    setIsBattlefyLoaded(true);
                });
        }, 60000);
        // clearing interval
        return () => clearInterval(timer);
    });

    // Get the Oversight ongoing tournaments
    useEffectOnce(() => {
        axios
            .get("https://oe.tas.gg/feeds/ongoing")
            .then((response) => {
                setOngoingTournamentsOversight(response.data);
            })
            .catch((error) => {
                console.log(error);
                setOngoingTournamentsOversight([]);
            })
            .finally(() => {
                setIsOversightLoaded(true);
            });
    });

    // Check the latest ongoing Oversight tournaments every minute
    useEffectOnce(() => {
        const timer = setInterval(() => {
            axios
                .get("https://oe.tas.gg/feeds/ongoing")
                .then((response) => {
                    setOngoingTournamentsOversight(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    setOngoingTournamentsOversight([]);
                })
                .finally(() => {
                    setIsOversightLoaded(true);
                });
        }, 60000);
        // clearing interval
        return () => clearInterval(timer);
    });

    // Check if the data is loaded
    useEffect(() => {
        if (isALGSLoaded === true || isBattlefyLoaded === true || isOversightLoaded === true) {
            setIsLoaded(true);
        }
    }, [isALGSLoaded, isBattlefyLoaded, isOversightLoaded]);

    // Combine ongoing tournaments feeds
    useEffect(() => {
        if (isLoaded === true) {
            let combinedTournaments = [];

            // Combine the ALGS ongoing tournaments
            combinedTournaments = combinedTournaments.concat(ongoingTournamentsALGS);

            // Combine the Battlefy ongoing tournaments
            combinedTournaments = combinedTournaments.concat(ongoingTournamentsBattlefy);

            // Combine the Oversight ongoing tournaments
            combinedTournaments = combinedTournaments.concat(ongoingTournamentsOversight);

            // order by timestamp
            combinedTournaments.sort((a, b) => {
                return a.timestamp - b.timestamp;
            });

            setOngoingTournaments(combinedTournaments);
        }
    }, [isLoaded, ongoingTournamentsALGS, ongoingTournamentsBattlefy, ongoingTournamentsOversight]);

    // Display the ongoing tournaments content
    useEffect(() => {
        if (isLoaded === true) {
            let tournamentsArray = ongoingTournaments.map((tournament) => (
                <OngoingTournamentContainer
                    key={
                        tournament.category +
                        "-" +
                        tournament.info_url_slug_a +
                        "-" +
                        tournament.info_url_slug_b +
                        "-" +
                        tournament.timestamp
                    }
                    name={tournament.name}
                    category={tournament.category}
                    timestamp={tournament.timestamp}
                    matches={tournament.matches}
                    info_url_slug_a={tournament.info_url_slug_a}
                    info_url_slug_b={tournament.info_url_slug_b}
                    event_page={tournament.event_page === undefined ? false : tournament.event_page}
                />
            ));

            setOngoingTournamentsContent(tournamentsArray.slice(0, numToShow));
        }
    }, [ongoingTournaments, numToShow, isLoaded]);

    return (
        <Row
            className="anchor"
            id="ot"
        >
            <Col className="col-12">
                <Card className="bg-dark text-white">
                    <Card.Header>
                        <h3 className="text-center">Ongoing Tournaments</h3>
                        {isLoaded && ongoingTournaments.length > 0 ? (
                            <>
                                Showing {numToShow > ongoingTournaments.length ? ongoingTournaments.length : numToShow}{" "}
                                of {ongoingTournaments.length} ongoing tournaments
                            </>
                        ) : null}
                    </Card.Header>
                    <Card.Body>
                        {isLoaded ? (
                            ongoingTournaments.length > 0 ? (
                                <Row>{ongoingTournamentsContent}</Row>
                            ) : (
                                <>No ongoing tournaments currently.</>
                            )
                        ) : (
                            <div className="text-center col-auto mx-auto">
                                <Spinner
                                    animation="border"
                                    variant="primary"
                                    style={{
                                        width: "3rem",
                                        height: "3rem",
                                    }}
                                />
                            </div>
                        )}
                        {numToShow > viewingIncrement || numToShow < ongoingTournaments.length ? (
                            <div className="text-center col-auto mx-auto">
                                {numToShow > viewingIncrement ? (
                                    <button
                                        className="btn btn-primary btn-sm me-2"
                                        onClick={() => {
                                            setNumToShow(numToShow - viewingIncrement);
                                            trackEvent({
                                                category: "Ongoing-Tournaments",
                                                action: "Show-Less",
                                                name: "Show Less Upcoming Tournaments",
                                            });
                                        }}
                                    >
                                        {" "}
                                        Show Less
                                    </button>
                                ) : null}
                                {numToShow < ongoingTournaments.length ? (
                                    <button
                                        className="btn btn-primary btn-sm ms-2"
                                        onClick={() => {
                                            setNumToShow(numToShow + viewingIncrement);
                                            trackEvent({
                                                category: "Ongoing-Tournaments",
                                                action: "Show-More",
                                                name: "Show More Upcoming Tournaments",
                                            });
                                        }}
                                    >
                                        {" "}
                                        Show More
                                    </button>
                                ) : null}
                            </div>
                        ) : null}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default OngoingTournamentsContainer;
