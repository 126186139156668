import { useEffect } from "react";
import { Row, Col, Badge, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";

function OngoingTournamentContainer(props) {
    const { trackEvent } = useMatomo();
    const [showAllMatches, setShowAllMatches] = useState(false);
    const [matchesToShow, setMatchesToShow] = useState([]);
    const [matchesContent, setMatchesContent] = useState(null);
    const numOfMatchesToShow = 3;

    let tournamentPageUrl = "/ongoing/" + props.category + "/" + props.info_url_slug_b;

    let dateString = new Date(props.timestamp * 1000);

    useEffect(() => {
        // Show all matches
        if (showAllMatches === true) {
            setMatchesToShow(props.matches);
        }
        // Show only the first three matches
        else {
            setMatchesToShow(props.matches.slice(0, numOfMatchesToShow));
        }
    }, [showAllMatches, props.matches]);

    // Generate matchesContent
    useEffect(() => {
        setMatchesContent(
            matchesToShow.map((match) => (
                <Col
                    xs="6"
                    className="text-center"
                    key={match.match_id}
                >
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 0 }}
                        overlay={
                            <Tooltip style={{ fontSize: "0.6rem", top: "-9999px", left: "-9999px" }}>
                                Generate a chat command for:
                                <br />
                                <b>
                                    {props.name}: {match.name}
                                </b>
                            </Tooltip>
                        }
                    >
                        <Link
                            to={"/match/" + props.category + "/" + match.match_id}
                            className="btn btn-dark btn-sm m-1 p-1"
                        >
                            {match.name}
                        </Link>
                    </OverlayTrigger>
                </Col>
            ))
        );
    }, [matchesToShow, props.category, props.name]);

    return (
        <>
            <Col
                xs="12"
                lg="6"
                xxl="4"
            >
                <Card className="bg-secondary m-2 shadow-sm">
                    <Link
                        to={tournamentPageUrl}
                        className="text-white"
                        style={{ textDecoration: "none", pointerEvents: props.info_url_slug_b === null ? "none" : "" }}
                    >
                        <Card.Header className="p-1">
                            <h6 className="text-center p-0 m-0">
                                {props.category === "ALGS" ? (
                                    <Badge
                                        bg="danger"
                                        className="shadow-sm"
                                    >
                                        ALGS
                                    </Badge>
                                ) : null}
                                {props.category === "Battlefy" ? (
                                    <Badge
                                        bg="primary"
                                        className="shadow-sm"
                                    >
                                        Battlefy
                                    </Badge>
                                ) : null}
                                {props.category === "Oversight" ? (
                                    <Badge
                                        bg="warning"
                                        className="shadow-sm text-dark"
                                    >
                                        Oversight Esports
                                    </Badge>
                                ) : null}{" "}
                                {props.name}
                            </h6>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 0 }}
                                overlay={
                                    <Tooltip
                                        style={{
                                            textDecoration: "none",
                                            fontSize: "0.55rem",
                                            top: "-9999px",
                                            left: "-9999px",
                                        }}
                                    >
                                        Date/time is shown in your local timezone region of:{" "}
                                        <b>{Intl.DateTimeFormat().resolvedOptions().timeZone}</b>
                                    </Tooltip>
                                }
                            >
                                <div
                                    className="text-center"
                                    style={{
                                        textDecoration: "none",
                                        fontSize: "0.6rem",
                                    }}
                                >
                                    {dateString.toLocaleString(navigator.languages[0], {
                                        weekday: "long",
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                        second: "numeric",
                                    })}
                                </div>
                            </OverlayTrigger>
                        </Card.Header>
                    </Link>
                    <Card.Body className="p-0">
                        {props.event_page !== false ? (
                            <center
                                style={{ fontSize: "0.75rem" }}
                                className="mb-2"
                            >
                                <a
                                    href={props.event_page}
                                    className="text-white fw-bold"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    View Tournament Information Page on {props.category}
                                </a>
                            </center>
                        ) : null}
                        <div className="text-center fw-bold">Matches:</div>
                        {props.matches.length === 0 ? (
                            <div className="text-center fst-italic">Tournament has no matches setup yet.</div>
                        ) : null}
                        <Row>
                            {matchesContent}
                            {props.matches.length > numOfMatchesToShow ? (
                                <>
                                    {showAllMatches ? (
                                        <Col className="text-center">
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 0 }}
                                                overlay={
                                                    <Tooltip
                                                        style={{
                                                            textDecoration: "none",
                                                            fontSize: "0.55rem",
                                                            top: "-9999px",
                                                            left: "-9999px",
                                                        }}
                                                    >
                                                        Show minimal matches for this tournament
                                                    </Tooltip>
                                                }
                                            >
                                                <button
                                                    className="btn btn-light btn-sm m-1 p-1 text-dark fw-bold"
                                                    onClick={() => {
                                                        setShowAllMatches(false);
                                                        trackEvent({
                                                            category: "Tournament",
                                                            action: "Show-Less-Matches",
                                                            name: props.name + " - Show less matches",
                                                        });
                                                    }}
                                                >
                                                    Show less matches
                                                </button>
                                            </OverlayTrigger>
                                        </Col>
                                    ) : (
                                        <Col className="text-center">
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 0 }}
                                                overlay={
                                                    <Tooltip
                                                        style={{
                                                            textDecoration: "none",
                                                            fontSize: "0.55rem",
                                                            top: "-9999px",
                                                            left: "-9999px",
                                                        }}
                                                    >
                                                        Show all matches for this tournament
                                                    </Tooltip>
                                                }
                                            >
                                                <button
                                                    className="btn btn-light btn-sm m-1 p-1 text-dark fw-bold"
                                                    onClick={() => {
                                                        setShowAllMatches(true);
                                                        trackEvent({
                                                            category: "Tournament",
                                                            action: "Show-All-Matches",
                                                            name: props.name + " - Show all matches",
                                                        });
                                                    }}
                                                >
                                                    Show all matches
                                                </button>
                                            </OverlayTrigger>
                                        </Col>
                                    )}
                                </>
                            ) : null}
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
}

export default OngoingTournamentContainer;
