import axios from "axios";
import React, { useState, useEffect, Fragment } from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useEffectOnce } from "../../functions/useEffectOnce";
import { Form, InputGroup, Card, Row, Col, ListGroup, ListGroupItem } from "react-bootstrap";
import BotCommands from "../BotCommands";

function ALGSTeamCommandBuilder(props) {
    const { trackPageView, trackEvent } = useMatomo();
    const [baseDataLoaded, setBaseDataLoaded] = useState(false);
    const [baseData, setBaseData] = useState([]);
    const [initialPreviewLoaded, setInitialPreviewLoaded] = useState(false);
    const [initialExampleLoaded, setInitialExampleLoaded] = useState(false);
    const [showTitle, setShowTitle] = useState(true);
    const [showCustomTitle, setShowCustomTitle] = useState(false);
    const [customTitle, setCustomTitle] = useState("");
    const [customTitleTemp, setCustomTitleTemp] = useState("");
    const [showPoweredBy, setShowPoweredBy] = useState(true);
    const [preview, setPreview] = useState("Loading...");
    const [example, setExample] = useState("Loading...");
    const [commandURL, setCommandURL] = useState("");

    const [possibleTeams, setPossibleTeams] = useState([]);
    const [loadingPossibleTeams, setLoadingPossibleTeams] = useState(true);
    const [selectedTeam, setSelectedTeam] = useState(null);

    // Get match info
    useEffectOnce(() => {
        let requestUrl = "https://algs.tas.gg/api/feeds/match_data/" + props.matchID;
        axios.get(requestUrl).then((response) => {
            setBaseData(response.data);
            setBaseDataLoaded(true);
        });
    });

    useEffectOnce(() => {
        let requestUrl = "https://algs.tas.gg/api/team/" + props.matchID + "/list-teams";
        axios.get(requestUrl).then((response) => {
            setPossibleTeams(response.data);
            setLoadingPossibleTeams(false);
            if (response.data.length > 0) {
                setSelectedTeam(response.data[0].team_id);
            }
        });
    });

    useEffect(() => {
        if (baseDataLoaded) {
            document.title =
                (baseData.region === "global" ? "" : baseData.region + ": ") +
                baseData.match +
                " - Tournaments - Twitch Apex Stats";
            trackPageView();
        }
    }, [baseDataLoaded, baseData, trackPageView]);

    // Get initial preview output
    useEffect(() => {
        if (selectedTeam === null) return;
        let urlOptions = "";
        if (showTitle === false) {
            urlOptions += "&noTitle";
        }
        if (showCustomTitle === true) {
            urlOptions += "&customTitle=" + customTitle;
        }
        if (showPoweredBy === true) {
            urlOptions += "&showPoweredBy";
        }

        setCommandURL(
            (
                "https://algs.tas.gg/api/team/" +
                props.matchID +
                "/" +
                selectedTeam +
                (urlOptions.length > 0 ? "?" + urlOptions.substring(1) : "")
            ).replaceAll(" ", "%20")
        );

        let requestUrl = "https://algs.tas.gg/api/team/" + props.matchID + "/" + selectedTeam + "?example" + urlOptions;
        axios.get(requestUrl).then((response) => {
            setPreview(response.data);
            setInitialPreviewLoaded(true);
        });
    }, [selectedTeam]); // eslint-disable-line react-hooks/exhaustive-deps

    // Get preview output if any of the options change
    useEffect(() => {
        if (initialPreviewLoaded === true) {
            let urlOptions = "";
            if (showTitle === false) {
                urlOptions += "&noTitle";
            }
            if (showCustomTitle === true) {
                urlOptions += "&customTitle=" + customTitle;
            }
            if (showPoweredBy === true) {
                urlOptions += "&showPoweredBy";
            }

            setCommandURL(
                (
                    "https://algs.tas.gg/api/team/" +
                    props.matchID +
                    "/" +
                    selectedTeam +
                    (urlOptions.length > 0 ? "?" + urlOptions.substring(1) : "")
                ).replaceAll(" ", "%20")
            );

            let requestUrl =
                "https://algs.tas.gg/api/team/" + props.matchID + "/" + selectedTeam + "?example" + urlOptions;
            axios.get(requestUrl).then((response) => {
                setPreview(response.data);
            });
        }
    }, [showTitle, showCustomTitle, customTitle, showPoweredBy, initialPreviewLoaded, props.matchID, selectedTeam]);

    // Check the latest ongoing tournaments every minute
    useEffect(() => {
        const timer = setInterval(() => {
            let urlOptions = "";
            if (showTitle === false) {
                urlOptions += "&noTitle";
            }
            if (showCustomTitle === true) {
                urlOptions += "&customTitle=" + customTitle;
            }
            if (showPoweredBy === true) {
                urlOptions += "&showPoweredBy";
            }

            setCommandURL(
                (
                    "https://algs.tas.gg/api/team/" +
                    props.matchID +
                    "/" +
                    selectedTeam +
                    (urlOptions.length > 0 ? "?" + urlOptions.substring(1) : "")
                ).replaceAll(" ", "%20")
            );

            let requestUrl =
                "https://algs.tas.gg/api/team/" + props.matchID + "/" + selectedTeam + "?example" + urlOptions;
            axios.get(requestUrl).then((response) => {
                setPreview(response.data);
                setInitialPreviewLoaded(true);
            });
        }, 60000);
        // clearing interval
        return () => clearInterval(timer);
    }, [showTitle, showCustomTitle, customTitle, showPoweredBy, props.matchID, selectedTeam]);

    // Get initial example output
    useEffectOnce(() => {
        let urlOptions = "";
        if (showTitle === false) {
            urlOptions += "&noTitle";
        }
        if (showCustomTitle === true) {
            urlOptions += "&customTitle=" + customTitle;
        }
        if (showPoweredBy === true) {
            urlOptions += "&showPoweredBy";
        }

        let requestUrl = "https://algs.tas.gg/api/team/13644/65960cb53986800a146d75ed?example" + urlOptions;
        axios.get(requestUrl).then((response) => {
            setExample(response.data);
            setInitialExampleLoaded(true);
        });
    });

    // Get example output if any of the options change
    useEffect(() => {
        if (initialExampleLoaded === true) {
            let urlOptions = "";
            if (showTitle === false) {
                urlOptions += "&noTitle";
            }
            if (showCustomTitle === true) {
                urlOptions += "&customTitle=" + customTitle;
            }
            if (showPoweredBy === true) {
                urlOptions += "&showPoweredBy";
            }

            let requestUrl = "https://algs.tas.gg/api/team/13644/65960cb53986800a146d75ed?example" + urlOptions;
            axios.get(requestUrl).then((response) => {
                setExample(response.data);
            });
        }
    }, [showTitle, showCustomTitle, customTitle, showPoweredBy, initialExampleLoaded]);

    // Wait 500ms after typing in custom title before updating previews
    useEffect(() => {
        const timeOutId = setTimeout(() => setCustomTitle(encodeURIComponent(customTitleTemp)), 500);
        return () => clearTimeout(timeOutId);
    }, [customTitleTemp]);

    return (
        <>
            <Card className="m-2">
                <Card.Header>
                    <h2 className="card-title">
                        {baseDataLoaded ? (
                            <>
                                {baseData.region === "global" ? null : baseData.region + ": "}
                                {baseData.match}
                            </>
                        ) : (
                            "Loading..."
                        )}
                    </h2>
                </Card.Header>
                <Card.Body>
                    <Fragment>
                        {!loadingPossibleTeams && selectedTeam !== null ? (
                            <Row>
                                <Col
                                    xs="12"
                                    className="pb-3"
                                >
                                    <ListGroup>
                                        <ListGroupItem
                                            active="true"
                                            key={"title"}
                                            className="text-center"
                                        >
                                            <span className="fw-bold h4">Select Team</span>
                                        </ListGroupItem>
                                        <ListGroupItem key={"select-team"}>
                                            <Form.Select
                                                aria-label="Select Team"
                                                onChange={(e) => {
                                                    setSelectedTeam(e.target.value);
                                                }}
                                                value={selectedTeam}
                                            >
                                                {possibleTeams.map((team) => (
                                                    <option
                                                        key={team.team_id}
                                                        value={team.team_id}
                                                    >
                                                        {team.team_name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </ListGroupItem>
                                    </ListGroup>
                                </Col>
                            </Row>
                        ) : (
                            <>
                                {loadingPossibleTeams ? (
                                    <div className="text-center fw-bold h4">Loading...</div>
                                ) : (
                                    <div className="text-center fw-bold h4">
                                        No teams assigned to this match yet. Check back after the match has started.
                                    </div>
                                )}
                            </>
                        )}
                    </Fragment>
                    {!loadingPossibleTeams && selectedTeam !== null && (
                        <Fragment>
                            <Row>
                                <Col
                                    xs="12"
                                    md="6"
                                    className="pb-3"
                                >
                                    <center>
                                        <b>Preview Output:</b>
                                        <br />
                                        <span
                                            className="fw-light fst-italic"
                                            style={{ fontSize: "0.7rem" }}
                                        >
                                            (This is what your command would look like currently)
                                        </span>
                                        <div
                                            className="bg-black border border-white text-white text-start font-monospace p-1"
                                            style={{
                                                fontSize: "0.8rem",
                                                maxWidth: "350px",
                                            }}
                                        >
                                            {preview}
                                        </div>
                                        <br />
                                        <br />
                                        <b>Example Output:</b>
                                        <br />
                                        <span
                                            className="fw-light fst-italic"
                                            style={{ fontSize: "0.7rem" }}
                                        >
                                            (Example shown is Crazy Raccoon for APAC N PL Split 1, Day 1)
                                        </span>
                                        <div
                                            className="bg-black border border-white text-white text-start font-monospace p-1"
                                            style={{
                                                fontSize: "0.8rem",
                                                maxWidth: "350px",
                                            }}
                                        >
                                            {example}
                                        </div>
                                    </center>
                                </Col>
                                <Col
                                    xs="12"
                                    md="6"
                                    className="pb-3"
                                >
                                    <ListGroup>
                                        <ListGroupItem
                                            active="true"
                                            key={"title"}
                                        >
                                            Settings
                                        </ListGroupItem>
                                        <ListGroupItem key={"title-settings"}>
                                            <Form.Check
                                                checked={showTitle && !showCustomTitle}
                                                type="radio"
                                                key={"radio-show-title"}
                                                id="radio-show-title"
                                                label="Default Title"
                                                name="titleSetting"
                                                onChange={() => {
                                                    trackEvent({
                                                        category: "CMD-Builder",
                                                        action: "Use-Default-Title",
                                                        name:
                                                            baseData.region +
                                                            ": " +
                                                            baseData.match +
                                                            " - Use Default Title",
                                                    });
                                                    setShowTitle(true);
                                                    setShowCustomTitle(false);
                                                }}
                                            />
                                            <Form.Check
                                                checked={!showTitle}
                                                type="radio"
                                                key={"radio-show-no-title"}
                                                id="radio-show-no-title"
                                                label="No Title"
                                                name="titleSetting"
                                                onChange={() => {
                                                    trackEvent({
                                                        category: "CMD-Builder",
                                                        action: "Use-No-Title",
                                                        name:
                                                            baseData.region + ": " + baseData.match + " - Use No Title",
                                                    });
                                                    setShowTitle(false);
                                                    setShowCustomTitle(false);
                                                }}
                                            />
                                            <Form.Check
                                                checked={showCustomTitle}
                                                type="radio"
                                                key={"radio-show-custom-title"}
                                                id="radio-show-custom-title"
                                                label="Custom Title"
                                                name="titleSetting"
                                                onChange={() => {
                                                    trackEvent({
                                                        category: "CMD-Builder",
                                                        action: "Use-Custom-Title",
                                                        name:
                                                            baseData.region +
                                                            ": " +
                                                            baseData.match +
                                                            " - Use Custom Title",
                                                    });
                                                    setShowTitle(true);
                                                    setShowCustomTitle(true);
                                                }}
                                            />
                                            {showCustomTitle ? (
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">Custom Title</InputGroup.Text>
                                                    <Form.Control
                                                        value={customTitleTemp}
                                                        key={"input-custom-title"}
                                                        onChange={(e) => setCustomTitleTemp(e.target.value)}
                                                    />
                                                </InputGroup>
                                            ) : null}
                                        </ListGroupItem>
                                        <ListGroupItem key={"show-powered-by-settings"}>
                                            <Form.Check
                                                checked={showPoweredBy}
                                                type="switch"
                                                id="switch-show-powered-by"
                                                key={"switch-show-powered-by"}
                                                label={[
                                                    'Show "Powered by TAS.gg"',
                                                    <span
                                                        className="fw-light fst-italic"
                                                        key={"powered-by-subtext"}
                                                        style={{ fontSize: "0.7rem" }}
                                                    >
                                                        (Not required, but is appreciated to promote this service)
                                                    </span>,
                                                ]}
                                                onChange={() => {
                                                    if (showPoweredBy) {
                                                        trackEvent({
                                                            category: "CMD-Builder",
                                                            action: "Hide-PoweredBy",
                                                            name:
                                                                baseData.region +
                                                                ": " +
                                                                baseData.match +
                                                                " - Hide Powered By",
                                                        });
                                                    } else {
                                                        trackEvent({
                                                            category: "CMD-Builder",
                                                            action: "Show-PoweredBy",
                                                            name:
                                                                baseData.region +
                                                                ": " +
                                                                baseData.match +
                                                                " - Show Powered By",
                                                        });
                                                    }
                                                    setShowPoweredBy(!showPoweredBy);
                                                }}
                                            />
                                        </ListGroupItem>
                                    </ListGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="12">
                                    <BotCommands
                                        teamResults={true}
                                        commandURL={commandURL}
                                        matchName={baseData.region + ": " + baseData.match}
                                    />
                                </Col>
                            </Row>
                        </Fragment>
                    )}
                </Card.Body>
            </Card>
        </>
    );
}

export default ALGSTeamCommandBuilder;
