import React, { useState } from "react";
import { Row, Col, Card, Form, InputGroup, Button, Modal } from "react-bootstrap";
import ALSLogo from "../imgs/als_logo.png";
import { useNavigate } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useEffectOnce } from "../functions/useEffectOnce";
import ALSGameID from "../imgs/als_dgs_game_id.png";

function ALSDGSPage(props) {
    const { trackPageView } = useMatomo();
    const [tournamentId, setTournamentId] = useState("");
    const [showInvalidIDModal, setShowInvalidIDModal] = useState(false);
    const navigate = useNavigate();

    useEffectOnce(() => {
        document.title = "ALS DGS Tournament - Tournaments - Twitch Apex Stats";
        trackPageView();
    });

    // Scroll to top of page
    useEffectOnce(() => {
        window.scrollTo(0, 0);
    });

    return (
        <Row>
            <Col className="col-12">
                <Card className="bg-dark text-white">
                    <Card.Header>
                        <h3 className="text-center">
                            <img
                                src={ALSLogo}
                                alt="Apex Legends Status logo"
                                style={{
                                    maxHeight: "calc(1.3rem + 0.6vw)",
                                    backgroundColor: "#DDDDDD",
                                    borderRadius: "50%",
                                    padding: "0.1rem",
                                }}
                            />{" "}
                            Apex Legends Status
                            <br />
                            <span className="h4">DGS Tournaments</span>
                        </h3>
                    </Card.Header>
                    <Card.Body className="text-center">
                        <a
                            href="https://apexlegendsstatus.com/tournament"
                            className="fw-bold"
                            target="_blank"
                            rel="noreferrer"
                        >
                            DGS
                        </a>{" "}
                        is a tool developed by{" "}
                        <a
                            href="https://apexlegendsstatus.com"
                            target="_blank"
                            className="fw-bold"
                            rel="noreferrer"
                        >
                            Apex Legends Status
                        </a>{" "}
                        that allows tournament administrators to create easily viewed detailed statistics about an Apex
                        Legends tournament. This tool is an advancement over their Custom Games Stats tool by allowing
                        more in-depth info and can be used on tournaments without a stats token. This tool provides a
                        wide range of statistics, including placements, points, kills, damage dealt, and more, both on
                        an overall tournament basis and on a per game basis. One of the main features is being able to
                        replay a match and look at all sorts of stats at that moment in the game. This can be extremely
                        helpful for tournament organizers to track the progress of their event, and for players to see
                        how they are performing compared to their competitors, as well for viewers to see how all the
                        competitors have been performing. The tool is user-friendly and can be customized to meet the
                        specific needs of the tournament administrator.
                        <br />
                        <br />
                        <a
                            href="https://apexlegendsstatus.com/tournament"
                            className="fw-bold text-center"
                            target="_blank"
                            rel="noreferrer"
                        >
                            View more details about the tool here.
                        </a>
                        <hr />
                        <h4>Generate Tournament Command</h4>
                        All that is needed to generate a tournament command is the Tournament ID.
                        <br />
                        <br />
                        <img
                            src={ALSGameID}
                            alt="ALS Game ID"
                            style={{ maxWidth: "100%" }}
                        />
                        <br />
                        <span className="text-muted">The Tournament ID is this number that is part of the URL. </span>
                        <br />
                        <br />
                        <InputGroup>
                            <InputGroup.Text className="fw-bold d-none d-lg-block">
                                Tournament
                                <br />
                                ID
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Enter ID (For example: 900)"
                                onChange={(e) => {
                                    // Check if someone pasted in the full URL
                                    if (e.target.value.includes("https://apexlegendsstatus.com/tournament/results/")) {
                                        setTournamentId(e.target.value.split("/")[5]);
                                        e.target.value = e.target.value.split("/")[5];
                                    } else if (e.target.value.includes("apexlegendsstatus.com/tournament/results/")) {
                                        setTournamentId(e.target.value.split("/")[3]);
                                        e.target.value = e.target.value.split("/")[3];
                                    } else {
                                        // Remove any non-numbers
                                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                        setTournamentId(e.target.value);
                                    }
                                }}
                                // When the user presses enter, navigate to the match page
                                onKeyUp={(e) => {
                                    if (
                                        e.key === "Enter" &&
                                        tournamentId !== "" &&
                                        tournamentId !== undefined &&
                                        tournamentId !== null &&
                                        tournamentId.length > 0
                                    ) {
                                        navigate(`/match/ALSDGS/${tournamentId}`);
                                    }
                                }}
                                className="fs-4"
                            />
                            <Button
                                to={`/match/ALSDGS/${tournamentId}`}
                                variant="primary"
                                className="fw-bold"
                                disabled={
                                    tournamentId === "" ||
                                    tournamentId === undefined ||
                                    tournamentId === null ||
                                    tournamentId.length === 0
                                }
                                onClick={() => {
                                    navigate(`/match/ALSDGS/${tournamentId}`);
                                }}
                                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                            >
                                Generate
                                <br />
                                Command
                            </Button>
                        </InputGroup>
                    </Card.Body>
                </Card>
                <InvalidIDModal
                    show={showInvalidIDModal}
                    tournamentID={tournamentId}
                    onHide={() => setShowInvalidIDModal(false)}
                />
            </Col>
        </Row>
    );
}

function InvalidIDModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Invalid ID for Apex Legends Status DGS</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>
                    You entered "<b>{props.tournamentID}</b>" which isn't a valid ID for Apex Legends Status Custom Game
                    Stats.
                </h4>
                <p>
                    The ID you entered is not a valid ID for Apex Legends Status DGS. Please make sure you follow the
                    directions on where to find the ID. You may supply either the ALS Sharing Code or the Game ID.
                </p>
                {props.tournamentID[0] === "p" ? (
                    <p className="text-warning">
                        <b>
                            It looks like you may have entered a player lobby code instead. If the tournament you want
                            is not currently on DGS on Apex Legends Status, try asking your tournament admin to create
                            the tournament on there. The instructions they need to create the tournament can be found{" "}
                            <a
                                href="https://apexlegendsstatus.com/custom-games/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                here.
                            </a>
                        </b>
                    </p>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ALSDGSPage;
