import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useEffectOnce } from "../functions/useEffectOnce";
import { useParams } from "react-router-dom";
import ALGSAllMatchesView from "../components/algs/ALGSAllMatchesView";

function PageALGS(props) {
    const { trackPageView } = useMatomo();
    const { league, split, region } = useParams();

    useEffectOnce(() => {
        document.title = "ALGS - Tournaments - Twitch Apex Stats";
        trackPageView();
    });

    // Scroll to top of page
    useEffectOnce(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <ALGSAllMatchesView
                league={league}
                split={split}
                region={region}
                isLeagueSet={league !== undefined}
                isSplitSet={split !== undefined}
                isRegionSet={region !== undefined}
            />
        </>
    );
}

export default PageALGS;
