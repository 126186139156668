import React, { useEffect } from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useEffectOnce } from "../functions/useEffectOnce";
import { useState } from "react";
import { Col, Card, Spinner, Button, Pagination } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";

function PageOversight(props) {
    const { trackPageView } = useMatomo();
    const [isLoaded, setIsLoaded] = useState(false);
    const [eventsData, setEventsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffectOnce(() => {
        document.title = "Oversight Esports - Tournaments - Twitch Apex Stats";
        trackPageView();
    });

    // Scroll to top of page
    useEffectOnce(() => {
        window.scrollTo(0, 0);
    });

    useEffectOnce(() => {
        axios
            .get("https://oe.tas.gg/feeds/all")
            .then((response) => {
                setEventsData(response.data);
                setIsLoaded(true);
            })
            .catch((error) => {
                console.log(error);
            });
    });

    // Update every 1 minute the events data
    useEffectOnce(() => {
        const interval = setInterval(() => {
            axios
                .get("https://oe.tas.gg/feeds/all")
                .then((response) => {
                    setEventsData(response.data);
                    setIsLoaded(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        }, 60000);
        return () => clearInterval(interval);
    });

    // Make sure page number is valid
    useEffect(() => {
        if (isLoaded) {
            if (currentPage < 1) {
                setCurrentPage(1);
            } else if (currentPage > Math.ceil(eventsData.length / 8)) {
                setCurrentPage(Math.ceil(eventsData.length / 8));
            }
        }
    }, [isLoaded, currentPage, eventsData]);

    return (
        <>
            <Card className="mt-3 mb-3 bg-dark">
                <Card.Header>
                    <h2 className="text-center">Oversight Esports Events</h2>
                </Card.Header>
                <Card.Body>
                    <center className="fs-6">
                        Select the event you want from the list of available events below.
                        <br />
                    </center>
                    {isLoaded === true ? (
                        <div>
                            <Col
                                xs={12}
                                className="mt-4"
                            >
                                <Pagination className="justify-content-center">
                                    <Pagination.Item
                                        key="Page"
                                        disabled
                                        className="fw-bold fs-6"
                                    >
                                        Page
                                    </Pagination.Item>
                                    <Pagination.Prev
                                        disabled={currentPage === 1}
                                        onClick={() => setCurrentPage(currentPage - 1)}
                                        className="fw-bold fs-6"
                                    />
                                    {Array.from(Array(Math.ceil(eventsData.length / 8)).keys()).map((i) => (
                                        <Pagination.Item
                                            key={"Streams-Page" + (i + 1)}
                                            active={currentPage === i + 1}
                                            onClick={() => setCurrentPage(i + 1)}
                                            className="fw-bold fs-6"
                                        >
                                            {i + 1}
                                        </Pagination.Item>
                                    ))}
                                    <Pagination.Next
                                        disabled={currentPage === Math.ceil(eventsData.length / 8)}
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                        className="fw-bold fs-6"
                                    />
                                </Pagination>
                            </Col>
                            {eventsData.slice((currentPage - 1) * 8, currentPage * 8).map((event) => (
                                <div key={event.id}>
                                    <Link to={"/match/Oversight/" + event.id}>
                                        <Button
                                            variant="primary"
                                            className="mt-3"
                                        >
                                            {event.event_title}
                                        </Button>
                                    </Link>
                                </div>
                            ))}
                            <Col
                                xs={12}
                                className="mt-4"
                            >
                                <Pagination className="justify-content-center">
                                    <Pagination.Item
                                        key="Page"
                                        disabled
                                        className="fw-bold fs-6"
                                    >
                                        Page
                                    </Pagination.Item>
                                    <Pagination.Prev
                                        disabled={currentPage === 1}
                                        onClick={() => setCurrentPage(currentPage - 1)}
                                        className="fw-bold fs-6"
                                    />
                                    {Array.from(Array(Math.ceil(eventsData.length / 8)).keys()).map((i) => (
                                        <Pagination.Item
                                            key={"Streams-Page" + (i + 1)}
                                            active={currentPage === i + 1}
                                            onClick={() => setCurrentPage(i + 1)}
                                            className="fw-bold fs-6"
                                        >
                                            {i + 1}
                                        </Pagination.Item>
                                    ))}
                                    <Pagination.Next
                                        disabled={currentPage === Math.ceil(eventsData.length / 8)}
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                        className="fw-bold fs-6"
                                    />
                                </Pagination>
                            </Col>
                        </div>
                    ) : (
                        <div className="text-center col-auto mx-auto">
                            <Spinner
                                animation="border"
                                variant="primary"
                                style={{
                                    width: "3rem",
                                    height: "3rem",
                                }}
                            />
                        </div>
                    )}
                </Card.Body>
            </Card>
        </>
    );
}

export default PageOversight;
