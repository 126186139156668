import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Nav, Button } from "react-bootstrap";
import { TrophyFill, Paypal, Discord } from "react-bootstrap-icons";
import { TbArrowBackUp } from "react-icons/tb";
import { FaPatreon } from "react-icons/fa";

import ALSLogo from "../../imgs/als_logo.png";

import "../../css/dashboard.css";

function MainMenuListing(props) {
    const location = useLocation();

    return (
        <>
            <Nav.Link href="https://twitchapexstats.com">
                <TbArrowBackUp /> Back to Twitch Apex Stats
            </Nav.Link>
            <br />
            <Nav.Link
                as={Link}
                eventKey="/"
                to="/"
                className={location.pathname === "/" ? "active" : null}
            >
                <TrophyFill /> Tournaments Home
            </Nav.Link>
            <Nav.Link
                as={Link}
                eventKey="/ongoing"
                to="/ongoing"
                className={location.pathname.split("/")[1] === "ongoing" ? "active" : null}
            >
                Ongoing Tournaments
            </Nav.Link>
            <Nav.Link
                as={Link}
                eventKey="/upcoming"
                to="/upcoming"
                className={location.pathname.split("/")[1] === "upcoming" ? "active" : null}
            >
                Upcoming Tournaments
            </Nav.Link>

            <br />

            <Nav.Link
                as={Link}
                eventKey="/algs"
                to="/algs"
                className={location.pathname.split("/")[1] === "algs" ? "active" : null}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="16"
                    height="18"
                    viewBox="0 0 50 50"
                    style={{ fill: "#333333" }}
                >
                    <path d="M45.874,39.515l-20-36c-0.353-0.635-1.396-0.635-1.748,0l-20,36c-0.237,0.426-0.132,0.962,0.25,1.267l7.5,6 C12.059,46.928,12.279,47,12.5,47c0.24,0,0.479-0.086,0.669-0.257l10-9c0.308-0.276,0.413-0.715,0.265-1.102S22.914,36,22.5,36 h-4.422l6.972-14.764L32.841,36H28.5c-0.414,0-0.785,0.255-0.934,0.642s-0.043,0.825,0.265,1.102l10,9 c0.384,0.345,0.968,0.342,1.347-0.009l6.5-6C46.017,40.422,46.098,39.918,45.874,39.515z"></path>
                </svg>{" "}
                ALGS - All Matches
            </Nav.Link>
            <Nav.Link
                as={Link}
                eventKey="/als-dgs"
                to="/als-dgs"
                className={location.pathname === "/als-dgs" ? "active" : null}
            >
                <img
                    src={ALSLogo}
                    alt="Apex Legends Status logo"
                    style={{ maxHeight: "0.9rem" }}
                />{" "}
                Apex Legends Status DGS Tournaments
            </Nav.Link>
            <Nav.Link
                as={Link}
                eventKey="/als"
                to="/als"
                className={location.pathname === "/als" ? "active" : null}
            >
                <img
                    src={ALSLogo}
                    alt="Apex Legends Status logo"
                    style={{ maxHeight: "0.9rem" }}
                />{" "}
                Apex Legends Status Custom Game Stats
            </Nav.Link>
            {/*
            <Nav.Link
                as={Link}
                eventKey="/oversight"
                to="/oversight"
                className={location.pathname === "/oversight" ? "active" : null}
            >
                Oversight Esports - All Events
    </Nav.Link>*/}
            <Button
                href="https://patreon.com/TwitchApexStats"
                target="_blank"
                className="btn btn-sm btn-secondary m-2 mt-5 text-black"
                style={{ backgroundColor: "#f96854" }}
            >
                <FaPatreon /> Patreon
            </Button>

            <br />
            <Button
                href="https://www.paypal.com/donate/?business=C9WLUSATV3Z9C&no_recurring=0&item_name=Twitch+Apex+Stats+is+ran+to+help+the+Apex+community.+I+would+love+for+your+help+to+make+sure+it+sticks+around+for+the+future.&currency_code=USD"
                target="_blank"
                className="btn btn-sm btn-secondary m-2 text-black"
                style={{ backgroundColor: "#009cde" }}
            >
                <Paypal /> PayPal Donate
            </Button>

            <br />
            <Button
                href="https://discord.gg/hkGgMZkanz"
                target="_blank"
                className="btn btn-sm btn-secondary m-2 mb-3"
            >
                <Discord /> Discord Server
            </Button>
        </>
    );
}

export default MainMenuListing;
