import React from "react";
import { useParams } from "react-router-dom";
import TournamentDetails from "../components/feeds/TournamentDetails";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "../functions/useEffectOnce";

function PageUpcomingTournament(props) {
    const { category, eventID } = useParams();
    const navigate = useNavigate();

    // Scroll to top of page
    useEffectOnce(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <h1 className="text-center">Upcoming Tournament Details</h1>
            {window.history.length > 1 ? (
                <div className="text-center">
                    <button className="btn btn-primary" onClick={() => navigate(-1)}>
                        Go Back
                    </button>
                </div>
            ) : null}
            <TournamentDetails category={category} eventID={eventID} timeframe="upcoming" />
            {window.history.length > 1 ? (
                <div className="text-center">
                    <button className="btn btn-primary" onClick={() => navigate(-1)}>
                        Go Back
                    </button>
                </div>
            ) : null}
        </>
    );
}

export default PageUpcomingTournament;
