import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useEffectOnce } from "../functions/useEffectOnce";

import OngoingTournamentsContainer from "../components/feeds/OngoingTournamentsContainer";

function PageOngoing(props) {
    const { trackPageView } = useMatomo();

    // Scroll to top of page
    useEffectOnce(() => {
        window.scrollTo(0, 0);
    });

    useEffectOnce(() => {
        document.title = "Ongoing Tournaments - Tournaments - Twitch Apex Stats";
        trackPageView();
    });

    return (
        <>
            <OngoingTournamentsContainer />
        </>
    );
}

export default PageOngoing;
