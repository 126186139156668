import React from "react";

function PageFooter(props) {
    return (
        <footer className="footer mt-auto text-center col-12 col-md-9 ms-sm-auto col-lg-10 px-md-4 p-1 bg-dark bg-gradient">
            <div className="container">
                <span className="text-muted">
                    Twitch Apex Stats is a project by{" "}
                    <a href="https://twitter.com/MrCaseyJames">
                        Casey Blackburn
                    </a>
                </span>
                <br />
                <div
                    className="text-muted fw-light lh-1"
                    style={{ fontSize: 12 }}
                >
                    Twitch is a registered trademark of Twitch Interactive. Apex
                    Legends is a registered trademark of Electronic Arts.
                    Trademarks are the property of their respective owners. This
                    site nor its services are the responsibility of Electronic
                    Arts or Twitch Interactive.
                </div>
            </div>
        </footer>
    );
}

export default PageFooter;
