// Packages
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Navbar, Row, Col, Container } from "react-bootstrap";

// CSS
import "./css/bootstrap-dark.min.css";
import "./css/dashboard.css";
import "./css/dashboard-viewports.css";

// Components
import MainMenuListing from "./components/base/MainMenuListing";
import PageFooter from "./components/base/PageFooter";

// Pages
import PageMain from "./pages/main.page";
import PageOngoing from "./pages/ongoing.page";
import PageOngoingTournament from "./pages/ongoingTournament.page";
import PageUpcoming from "./pages/upcoming.page";
import PageUpcomingTournament from "./pages/upcomingTournament.page";
import PageMatch from "./pages/match.page";
import PageALGS from "./pages/algs.page";
import PageALSCGS from "./pages/alscgs.page";
import PageALSDGS from "./pages/alsdgs.page";
import PageOversight from "./pages/oversight.page";

function App() {
    return (
        <Router>
            <Navbar
                collapseOnSelect={true}
                bg="dark"
                variant="dark"
                sticky="top"
                expand="md"
                className="flex-md-nowrap p-0 shadow"
                onSelect={(eventKey) => {
                    //console.log("Selected:", eventKey);
                }}
            >
                <Navbar.Brand
                    href="https://tas.gg"
                    className="col-7 col-sm-4 col-md-3 col-lg-2 me-0 px-3"
                >
                    <img
                        src="https://tournaments.tas.gg/android-chrome-192x192.png"
                        alt="Logo"
                        style={({ width: "30px" }, { height: "30px" })}
                    />
                    &nbsp;Twitch Apex Stats
                </Navbar.Brand>

                <Navbar.Toggle
                    id="sidebarButton"
                    aria-controls="sidebarMenu"
                    className="position-absolute"
                />
                <Navbar.Collapse
                    id="sidebarMenu"
                    className="col-7 col-sm-4 col-md-3 col-lg-2 d-md-none bg-light sidebar"
                >
                    <Col className="col-12 sidebar-sticky pt-3 bg-light">
                        <MainMenuListing />
                        <div>
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </Col>
                </Navbar.Collapse>
            </Navbar>
            <Container fluid>
                <Row>
                    <Col className="col-12 col-md-9 ms-sm-auto col-lg-10 px-md-4 pb-5">
                        <Container className="mt-5 p-0 p-lg-3 p-xl-5">
                            <Row>
                                <Col className="p-0 p-md-1">
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={<PageMain />}
                                        />
                                        <Route
                                            path="/ongoing"
                                            element={<PageOngoing />}
                                        />
                                        <Route
                                            path="/ongoing/:category/:eventID"
                                            element={<PageOngoingTournament />}
                                        />
                                        <Route
                                            path="/upcoming"
                                            element={<PageUpcoming />}
                                        />
                                        <Route
                                            path="/upcoming/:category/:eventID"
                                            element={<PageUpcomingTournament />}
                                        />
                                        <Route
                                            path="/match/:category/:matchID"
                                            element={<PageMatch />}
                                        />
                                        <Route
                                            path="/algs/1362"
                                            element={
                                                <Navigate
                                                    to="/match/ALGS/1362"
                                                    replace
                                                />
                                            }
                                        />
                                        <Route
                                            path="/algs"
                                            element={<PageALGS />}
                                        />
                                        <Route
                                            path="/algs/:league"
                                            element={<PageALGS />}
                                        />
                                        <Route
                                            path="/algs/:league/:split"
                                            element={<PageALGS />}
                                        />
                                        <Route
                                            path="/algs/:league/:split/:region"
                                            element={<PageALGS />}
                                        />
                                        <Route
                                            path="/als"
                                            element={<PageALSCGS />}
                                        />
                                        <Route
                                            path="/als-dgs"
                                            element={<PageALSDGS />}
                                        />
                                        <Route
                                            path="/oversight"
                                            element={<PageOversight />}
                                        />
                                        <Route
                                            path="*"
                                            element={
                                                <Navigate
                                                    to="/"
                                                    replace
                                                />
                                            }
                                        />
                                    </Routes>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <PageFooter />
        </Router>
    );
}

export default App;
